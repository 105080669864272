/* ....................................................................... 
 *  main   wrapper  
 *         article 
 *         aside nav  
 * ....................................................................... */
body main wrapper  {
	padding-top: var(--sl-spacing-x-large);
	display: grid;
	/* 11.8.23:   1fr --> 0.5 fr damit wird das rechte Grid kleiner */
	grid-template-columns: minmax(0, 2.5fr) 1fr;
	/* gap: var( --sl-spacing-medium ); */
	margin-left: auto;
	margin-right: auto;
/*	max-width: calc(var(--max-content-width) + 2.25rem); */

	@media (--card-minimal) {
		grid-template-columns: 1fr;
		gap: 0;
	}

	& article {
		& .subheader { 
			display: flex;
			justify-content: space-between;
		}
		& sl-card.article::part(base) { border-top: 0 solid #ffff; }
/*		@media (--nav-minimal) {  */
		/*	width: 100vw; */
			padding-left: var(--sl-spacing-medium);
			padding-right: var(--sl-spacing-medium);
/*		}  */
	}
	& aside  > nav { 
		font-size: var(--sl-font-size-small);
/*		padding: var(--sl-spacing-small);  */
		& div.table-container {    /* Weite der SL-Card-Container  */
		     width: var(--card-width) ;/* 100% */
				 margin: auto;  
				 & td { padding: 0 var(--sl-spacing-x-small); }
				 & table { margin: auto; }   /* zentriere Tabellen in cards */
			 }
		 }

	& aside > nav  > ul { 
			display: flex;
			flex-wrap: wrap;
			gap: var(--sl-spacing-large );
    }
	& aside > nav > ul > 	li { flex: 1 250px }
}
	
sl-card.blog .wrapper { 
	display: flex;
	justify-content: space-between;
}
