:root {   --heading-color: black;
  --action-color: #d64045;
}
body.home {
	--hero-primary: var(--sl-color-topo-50) ; /* #436178;  */
	--hero-primary-dark: var(--sl-color-topo-200) ; /*#413a52; */
	background: radial-gradient(var(--hero-primary) 25%, var(--hero-primary-dark) 100%); 
	z-index: 1;
  @media (--sl-breakpoint-xs) {
    --h1-font-size: var(--sl-font-size-2x-large);
    --h2-font-size: var(--sl-font-size-x-large);
  }
}

/* 
main {
 max-width: 1680px;  
 margin: auto;                       
}
*/
main > article > h1, main > h1 { 
	padding-top: var(--sl-spacing-2x-large);
	margin-bottom: var(--sl-spacing-2x-large);
}

main > article > h2,  main > section > h2  { padding-top: var(--sl-spacing-x-large); }
main > article > h3,  main > section > h3  { padding-top: var(--sl-spacing-large); }
main > article > h4,  main > section > h4  { padding-top: var(--sl-spacing-medium); }
main > article > pre, main > section> pre  { padding: var(--sl-spacing-large);   /* Symmetrische Abstände */
}

main > section{ 
/*	padding-left: var(--sl-spacing-3x-large);
	padding-right: var(--sl-spacing-3x-large); */
  & p { 
  font-size: var( --sl-font-size-large );
	@media( --sl-breakpoint-xs ) { 
  font-size: var( --sl-font-size-medium );
    }
   } 
  }
article {
	padding-left: var(--sl-spacing-3x-large);
	max-width: var( --max-content-width );

	& p { padding-top: var( --sl-spacing-small ) }
/* die einfache Lösung  */
	& ul { 
	list-style-type: circle;  
	list-style-position: outside;
	margin-left: var(--sl-spacing-medium);
	padding-left: var(--sl-spacing-medium);

	& li { padding: var(--sl-spacing-2x-small); }
/* 	list-style-type: symbols(cyclic '○' '●');  */

/*  siehe auch https://www.w3.org/TR/css-lists-3/#marker-pseudoelement  */
		}
}

/* Bilder immer zentriert einfügen */
figure { 
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
  & img { flex: 1  }
	& figcaption { 
	  flex: 1;
    flex-basis: 200px;	
	  align-self: end;
	  letter-spacing: var( --sl-letter-spacing-dense );}
}
preamble  > p { 
	padding:	var(--sl-spacing-3x-large);
	font-size: var(--sl-font-size-large);
	text-rendering: optimizeLegibility;
	letter-spacing: var(--sl-letter-spacing-normal);
}
blockquote {
	border-left: var(-sl.spacing-small) solid var(--sl-color-teal-800);
	color: var(--sl-color-teal-800);
	line-height: var(--sl-line-height-normal);
}
/*
	blockquote:before {
	content: "3";
	position: absolute;
	top: 50%;
	left: -4px;
	height: 2em;
	background-color: #fff;
	width: 5px;
	margin-top: -1em;
}
	blockquote:after {
	content: "";
	position: absolute;
	top: 50%;
	left: -0.5em;
	color: #00CC8F;
	font-family: "Ionicons";
	font-style: normal;
	line-height: 1em;
	text-align: center;
	text-indent: -2px;
	width: 1em;
	margin-top: -0.5em;
	transition: 0.2s all ease-in-out, 0.4s transform ease-in-out;
}
*/
.automation-card, .automation-card h4  { text-align: center }
.automation-card  [slot='footer'] {
	font-size: var( --sl-font-size-small );
	  display: contents;
    text-align: center;
	}
	.automation-card  [slot='header']{
    text-align: center;
	}

section-wraper{ 
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax( 275px, 2fr ));
		gap: var(--sl-spacing-3x-large);
		margin-left: auto;
		margin-right: auto;

		& img { display: inline; }


	}

