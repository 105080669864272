@font-face {
  font-family: 'Volkhov';
  src: url("../fonts/volkhov.ttf") format('truetype');
  font-weight: 400 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Volkhov';
  src: url("../fonts/volkhov.ttf") format('truetype');
  font-weight: 400 700;
  font-style: italic;
  font-display: fallback;
}
