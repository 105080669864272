
/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/light.css"; 
@import "../fonts/volkhov.css";  
@import "reset.css";
@import "global.css";
@import "breakpoints.css";
@import "shoelace.css";
@import "syntax.css";
@import "custom.css";
@import "footer.css";
@import "bar.css";
@import "hero.css";
@import "cards.css";
@import "blog.css";
@import "call.css"; 
@import "navbar.css";
@import "blog-divs.css";
@import "shark.css";
@import "night.css";
:root {
/*  Grundfarben und Dimensionen                             */
/*  wird in  global.css gesetzt                             */
/* Themenfarben                                             */
 --sl-color-topo-50: rgb(250 249 249);
 --sl-color-topo-100: rgb(237 236 236);
 --sl-color-topo-200: rgb(224 221 221);
 --sl-color-topo-300: rgb(210 206 206);
 --sl-color-topo-400: rgb(190 185 185);
 --sl-color-topo-500: rgb(166 158 158);
 --sl-color-topo-600: rgb(140 130 130);
 --sl-color-topo-700: rgb(117 105 105);
 --sl-color-topo-800: rgb(98 86 86);
 --sl-color-topo-900: rgb(70 61 61);
 --sl-color-topo-950: rgb(43 37 37);

--body-background: var(--sl-color-topo-100);
--body-color:  var(--sl-color-topo-950);
--link-color:  var(--sl-color-orange-500);
--hero-background-image: url("/images/topo-hero-bg-2.png");
--logo-text-shadow:
          0 0 10px var(  --sl-color-neutral-0  ),
          0 0 20px var(  --sl-color-neutral-0  ),
          0 0 30px var(  --sl-color-neutral-0  ),
          0 0 40px var(  --sl-color-blue-300  ),
          0 0 70px var(  --sl-color-blue-300  ),
          0 0 80px var(  --sl-color-blue-300  ),
          0 0 100px var( --sl-color-blue-300  ),
          0 0 150px var( --sl-color-blue-300  );
 

--max-body-width: 1680px;
--max-content-width: 65rem;


/*   card blog        */
	--blog-background: var(--sl-color-teal-50);
/*  card-grid backgrunds        */
  --card-primary: var(--sl-color-green-200); /* #ff6f59; */
  --card-primary-dark: var(--sl-color-blue-400); /* #c2422f; */
/*  card border top   */
	--card-top-color: var( --sl-color-orange-400 );
 


}
