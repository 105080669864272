/* Wird benutzt zur Einbettung des zweispaltigen Heros  (Blogbeitrag) */
hero-inlay {
	display: grid;
	grid-template-areas: "he";
	align-items: center;
	height: 65vh;
	max-height: 600px;
	background-color: var(--sl-color-topo-50);
	overflow: visible;
}

hero-inlay > * { grid-area: he; }
hero-inlay img {
	object-fit: cover;
	/* Image leicht nach links  und unten versetzen */
	object-position: 1vw -2vmin;  
	height: min(60vh, 500px);
	/* Bildbreite begrenzen */
	width: 80%;
	/* Bildobject selbst links anordnen  */
	justify-self:  end;
	transition: 180ms width ease-in;
	@media (min-width: 60rem) { width: 60%; } 
	@media (--sl-breakpoint-xs) { 
		width: 80%;  
		object-fit: contain;
		object-position: 1vw +50vmin;  
	}
}

hero-inlay article {
	overflow: visible;
	/* links Abstand zum Seitenrand einfügen      */
	margin: 1rem 0 1rem 5%;
	/* Hintergrund ist etwas größer, als der Text */
	padding: 0.5rem 0.5rem 0.5rem 0;
	/* Nutze nur einen Teil des Platzes, Rest ist fürs Image */
	max-width: 35%;
	min-width: 30ch;
	z-index: 1;
	background-color: var(--sl-color-transparent-50);
	border-radius: 1rem;
  
	& h1 {
		font-size: 3rem;
		color: var(--sl-color-topo-600);
		font-weight: 500;
		margin: -10rem 0 1rem;
	}
  & p { font-size: var(--sl-font-size-x-large);  }

	& sl-button { padding-top: var(--sl-spacing-x-large)  }
}
