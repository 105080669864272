div.call {
	display: inline-flex; 
	gap: var(--sl-spacing-2x-large);
	flex-direction: row;
  width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: var(--sl-spacing-large);
  flex-wrap: wrap;
  z-index: 2;
	background-color: var(--sl-color-topo-50); 
	overflow: visible;
}

/* div.call > * { grid-area: the-text; }  */
 div.call-box {
/* 	object-fit: cover; */
/* Image leicht nach links  und unten versetzen */
/*	object-position: 5vw -2vmin;  
/*	height: min(60vh, 500px);
/* Bildobject selbst links anordnen  */
	justify-self:  end;
	transition: 180ms width ease-in;
  padding-left: var(--sl-spacing-large );
	flex: 1 1 250px;
/*
 /*	@media (min-width: 60rem) { width: 60%; }   */
}


div.call section {
	overflow: visible;
	/* links Abstand zum Seitenrand einfügen      */
/*	margin: var( --sl-spacing-medium )  0  var( --sl-spacing-medium ) 5%;*/
	/* Hintergrund ist etwas größer, als der Text */
	padding: var( --sl-spacing-x-small ); 
	/* Nutze nur einen Teil des Platzes, Rest ist fürs Image */
/*	max-width: 85%;
	min-width: 30ch;
	z-index: 1; */
	flex: 1;
	flex-basis: 300px;
  
	& h1 {
		font-size: 3rem;
/*		color: var(--sl-color-topo-600); */
		font-weight: 500;
		margin: 0 0 1rem;
	}
  & p { font-size:var(--sl-font-size-medium);  }
	& ul {
		/* Zeichengröße für den Text der Aufzählung */
		font-size: var(--sl-font-size-2x-large);
		text-transform: uppercase;
		list-style-type: square;
		list-style-position: inside;
		/* Abstand zwischen Liste und Text */
		padding: var(--sl-spacing-4x-large);  
		text-align: left;
		@media( --sl-breakpoint-xs  ) {
			 font-size: var(--sl-font-size-x-large); 
		 }
	 }
	/*  chromium: 3x-large, firefox: 2x-large  */
	& li {
		text-indent: calc( var(--sl-spacing-2x-large) *-1);
	}
}

