night {
	overflow: hidden;
/*	width: 100vmax; */
	width: auto;
	height: auto;
	/*padding-top: 100vmax; */
	box-sizing: content-box;
	background: 
	/* moon */
	radial-gradient(farthest-side at 100% 0%, #258, #3690) 100% 0% / 60% 50%,
	radial-gradient(circle at 90% 20%, #495b99 6%, #495b9900 6.1%), 
	radial-gradient(circle at 89% 21%, #fff 6%, #fff0 6.1%),  
	radial-gradient(at 90% 20%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 25% 5%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 55% 5%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 75% 5%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 40% 5%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 30% 15%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 45% 15%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 60% 15%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 85% 15%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 80% 25%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 80% 35%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 60% 50%, #495b9988 6%, #495b9900 30%),
	radial-gradient(at 75% 35%, #495b99cc 6%, #495b9900 20%),
	radial-gradient(at 100% 45%, #495b9966 6%, #495b9900 20%),
	/* stars */
	radial-gradient(circle at 25% 15%, #fff6 0.15%, #fff0 0),
	radial-gradient(circle at 50% 5%, #fff6 0.14%, #fff0 0),
	radial-gradient(circle at 75% 35%, #fff6 0.15%, #fff0 0),
	radial-gradient(circle at 15% 55%, #fff6 0.2%, #fff0 0),
	radial-gradient(circle at 45% 5%, #fff6 0.175%, #fff0 0),
	radial-gradient(circle at 13% 27%, #fff6 0.15%, #fff0 0),
	radial-gradient(circle at 27% 10%, #fff6 0.14%, #fff0 0),
	radial-gradient(circle at 33% 35%, #fff6 0.15%, #fff0 0),
	radial-gradient(circle at 34% 34.9%, #fff6 0.16%, #fff0 0),
	radial-gradient(circle at 65% 37%, #fff6 0.175%, #fff0 0),
	radial-gradient(circle at 90% 7%, #fff6 0.65%, #fff0 0),
	radial-gradient(circle at 86% 3%, #fff6 0.2%, #fff0 0),
	radial-gradient(circle at 95% 15%, #fff6 0.175%, #fff0 0),
	radial-gradient(circle at 3% 7%, #fff6 0.135%, #fff0 0),
	radial-gradient(circle at 54% 57%, #fff6 0.14%, #fff0 0),
	radial-gradient(circle at 43% 24%, #fff6 0.15%, #fff0 0),
	/* shooting star */
	radial-gradient(farthest-side at 0 0, #4050a0 40%, #3490) 0% 0% / 35% 25%,
	linear-gradient(35deg, #0000 50%, #fff6 0 50.5%, #0000 0) 0% 0% / 20% 20%,
	radial-gradient(circle at 20% 16.9%, #ff0c 0.075%, #ff00 0);
	/* background mountains */
/*	radial-gradient(farthest-side at 0 100%, #8086, #8080) 0 100% / 60% 35%, */
/*	radial-gradient(farthest-side at 0 100%, #8086, #8080) 0 75% / 60% 35%,  */

	/* background sky and lake */
/*	radial-gradient(at 70% 0%, #dcf5, #cab0 50%) 100% 100% / 100% 15%,
/*	radial-gradient(100% 140% at 60% 50%, #1230, #1234) 0 85.5% / 100% 1%,*/
/*	linear-gradient(#349, #cab 85vmax, #cab 0, #65a);  */
	background-repeat: no-repeat;

}

