/*  ----------------------------------------------------- */
/*  Allgemein Cards                                       */
/*  ----------------------------------------------------- */
cards {
	display: grid;
	/*  hier wird die minimale Breite der Boxen festgelegt  */
	grid-template-columns: repeat(auto-fit, minmax(30ch, auto));
	column-gap: var(--sl-spacing-2x-large);
	row-gap:  var(--sl-spacing-2x-large);
  justify-content: stretch;
	/*		@media (--sl-breakpoint-xs) { grid-template-columns: 1fr; } */

		& sl-card { 
			height: 100%;
			width: 100%;
		}
	}
/*  ----------------------------------------------------- */
/*  Blog                                                  */
/*  ----------------------------------------------------- */
	cards.blog > sl-card {
		--border-width: 0;
		text-align: justify;
		&::part(base) {
			height: 100%;
			/*	background: radial-gradient(var(--card-primary) 25%, var(--card-primary-dark) 100%); */
			position: relative;
			/* 	box-shadow: inset 4px 4px 4px  var(--hero-primary-dark);  */
			box-shadow: var(--sl-shadow-x-large);
			background-color:  var( --blog-background );  /* Spezifisch topofocus */
	}}

/*  ----------------------------------------------------- */
/*  Card-Grid                                             */
/*  ----------------------------------------------------- */
	cards.card-grid {

		@media( min-width: 650px ) { 
			grid-template-areas: none;
			& :nth-of-type(3) { grid-column: 1 / -1; } 		
		}
		@media( --hero-fullsize) { 
			/* 	width: 100vw;  */
			grid-template-columns: repeat(auto-fit, minmax(15ch, 1fr));
			& :nth-of-type(3) { grid-column: auto  }
		}  


		list-style-type: none;
		padding-top: var(--sl-spacing-3x-large); 
		padding-bottom: var(--sl-spacing-3x-large); 

		& li {
			margin: 0 !important;
		}

		& sl-card {
			--padding: 2rem 1.25rem;
			--border-color: var(--sl-color-teal-700);
			--border-width: var(--sl-spacing-3x-small);
			--border-radius: var(--sl-border-radius-large);
			height: 100%;
			width: 100%;
			z-index: 1000;
			text-align: justify;
			/*	box-shadow: var(--sl-shadow-x-large);  */
			box-shadow: inset 40px 40px 40px  var(--hero-primary-dark);  


			&::part(base) {
				height: 100%;
				background: radial-gradient(var(--hero-primary) 25%, var(--hero-primary-dark) 100%); 
				position: relative;
				/* 	box-shadow: inset 4px 4px 4px  var(--hero-primary-dark);  */
				box-shadow: var(--sl-shadow-x-large);
				color: var(--sl-color-amber-300);
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 0;

				}
				&::after {
					background-position: 0 0;
					background-size: 10px 10px;
					background-image: linear-gradient(
					-45deg, rgba(0, 0, 0, .045) 25%,
					transparent 25%, transparent 50%,
					rgba(0, 0, 0, .045) 50%, rgba(0, 0, 0, .045) 75%,
					transparent 75%, transparent);
				}
			}
			/*  Überschriften der Cards                     */
			&::part(header), &::part(footer) {
				padding: 1rem 1.25rem;
				color: var(--sl-color-amber-400);
				font-size: var(--sl-font-size-x-large);
			}

			&::part(body) {
				flex-grow: 1;
			}

			& [slot="header"] h3 {
				margin: 0;
			}

			& [slot="footer"] {
				display: flex; 
				justify-content: space-between; 
				align-items: center;

				& :only-child {
					margin: 0 auto;
				}

				& img {
					width: 50px;
					border-radius: 50px;
					vertical-align: middle;
					margin-right: 0.3em;
				}
			}
		}   /* closing &sl-card */
	}

/*  ----------------------------------------------------- */
/*  Kartenbereich                                         */
/*  ----------------------------------------------------- */
.kartenbereich{ 
	z-index: 10;
	--card-primary: #1d1e268c;
	--card-primary-dark: #282336;
	padding: 0;
  margin-bottom: var(--sl-spacing-3x-large); 
  margin-top: var(--sl-spacing-3x-large); 
	& cards { 
		& sl-card {
			--border-radius: 0;
			--border-color: var(--sl-color-topo-500);
			--border-width: 0 ; /*var(--sl-spacing-3x-small);*/
			text-align: justify;
			&::part(base) {
				height: 100%;   /* alle Cards sind gleich groß */
				border-top: 0;
			}
			& p { font-size: var( --sl-font-size-medium ) }
			& [slot='header'] {
				display: flex;
				align-items: center; 
				align-content: top; 
				justify-content: space-between;
			}
		}
	}
}
/* Sonderbehandlung für pages  */
body.page > main > section> section.kartenbereich > cards {	
 @media( min-width: 650px ) { 
		grid-template-areas: none;
		& :nth-of-type(3) { grid-column: 1 / -1; } 		
		}
 @media( --hero-fullsize) { 

		& :nth-of-type(3) { grid-column: auto  }
		}  
	}

/* Sonderbehandlung für posts  */
body.post > main > wrapper > article > section.kartenbereich > cards {	
 @media( min-width: 850px ) { 
		grid-template-areas: none;
		& :nth-of-type(3) { grid-column: 1 / -1; } 		
		}
 @media( min-width: 3000px) { 
	  grid-template-columns: repeat(auto-fit, minmax(20ch, 1fr));

		& :nth-of-type(3) { grid-column: auto  }
		}  
	}
