body.home header { 
	background: 
		 	 radial-gradient(var(--sl-color-topo-200) 20% , var(--sl-color-topo-300) 140%, var(--sl-color-topo-50) 25%) ;
}

body.home footer {
	background: 
		 	 radial-gradient(var(--sl-color-sky-900) 20% , var(--sl-color-orange-900) 140%, var(--sl-color-topo-50) 25%) ;
 /* margin-top:  calc( var( --sl-spacing-2x-large ) * -1 ); */
	border-top: solid var( --sl-color-primary-500 );
}


header, div.the-wrapper {

	background: var( --hero-background-image );
	background-size: cover;
	background-repeat: no-repeat;   
}
header {
  position: relative;

	color: var( --sl-color-neutral-1000 );   /* Grundfarbe für Elemente          */
	font-weight: var(--sl-font-weight-bold); 
	text-align: left;    /* Darstellung ohne Flexbox Design  */
	margin-bottom: var( --sl-spacing-2x-large );
	& a { 
	  color: var( --sl-color-neutral-1000 );
		font-weight: var(--sl-font-weight-bold); 
 	}
} 

header.hero > div > p , header.page > h1 { 
        text-shadow: var(--logo-text-shadow); 
			}
header.page, header.post,header.modal  {
	display: flex;
		flex-direction: row-reverse;
		gap: calc( var( --sl-spacing-2x-large ) *-1);
/*		justify-content: start; */
	/* align-items: center;  */

	& h1 {
		hyphens: auto;
			margin: auto;
	/*		margin-left: 0; */
			flex: auto;
/*			margin-right: 30%;  */
/*			font-size: clamp(2rem, 0.4rem + 5.3333vw, 3rem);*/
			padding: var( --sl-spacing-medium );
			padding-top: var( --sl-spacing-x-large );
			padding-left: var( --sl-spacing-x-large );
			/* var(fluid-font-size-h1)  */
	}
	/*  Kein Konterfeil auf Mobilgeräten */
	@media( --sl-breakpoint-xs ) {
		& .canvas { display: none  }
		& h1 { margin: auto }
		& sl-avatar{ display: none }
	}
}
/* 
header.post {
	& section {  margin-left:0; } 
	&  h1 {
			margin-bottom: 0;
			margin-top: calc(var(--sl-spacing-3x-large) * -2); 
			padding: 0; /* var(--sl-spacing-small) var(--sl-spacing-3x-large);  */
/*	}
	
} */
.hero {
	/*	width: 100vw; */
/*  Umschalten auf Flexbox Darstellung  
*	@media ( --hero-fullsize ) {
*		background-position: center center;
*		padding: var(--sl-spacing-4X-large) var(--sl-spacing-2x-large) ;
*		display: flex;
*		align-items: end;
*		align-content: baseline;
*	} 
*	&:after{
*		width: 100%;
*		height: 100%;
*		background-color: #000;
*	}   /*  end hero-fullsize */
/*	& img {
*		margin: auto;
*		padding-top: var(--sl-spacing-medium);
*	} 
*/
	& div.the-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: var( --sl-spacing-2x-large );
		justify-content: space-between;
		& p {	
			flex: 1;
			/*  Zeichen sind etwas kleiner, als Liste */
			font-size: var(--sl-font-size-x-large);
			/*  Abstand des Textes vom Bildunterrand */
			padding-bottom: var(--sl-spacing-2x-large);
			/* die Elemente unten ausrichten */ 
			margin-top: 10vmin;
			text-align: center;
			align-content: last baseline;
		}
		& p.intro {
			text-align: left;
			font-size: var(--sl-font-size-3x-large);
			margin-top: 20vmin;
			padding-left: var( --sl-spacing-2x-large );
		}
		& ul {
			display: contents;
			margin-top: -30%;
			/* Zeichengröße für den Text der Aufzählung */
			font-size: var(--sl-font-size-2x-large);
			text-transform: uppercase;
			list-style-type: square;
			list-style-position: inside;
			/* Abstand zwischen Liste und Text */
			padding: var(--sl-spacing-4x-large);  
			text-align: left;
			@media( --sl-breakpoint-xs  ) {
				font-size: var(--sl-font-size-x-large); 
			}
			/*  chromium: 3x-large, firefox: 2x-large  */
			& li {
				text-indent: calc( var(--sl-spacing-3x-large) *-1);
				margin-left: calc( var(--sl-spacing-3x-large) * 2);
			}
		}
	}

}     /*  ende hero */

/*	background: radial-gradient(var(--hero-primary) 25%, var(--hero-primary-dark) 100%); 
	width: 100vw; */


/*  Spezifische Formatierung der Navigation für die Homepage */
/*  Entkommentieren für eine fixe Navigation                */
/* 
	#nav-bar { position: fixed ; z-index: 1000; top:0; left: 0; width: 100%; overflow: hiden; background-color: var(--sl-color-indigo-300); opacity: 45%; }
main {margin-top: 35px;}
*/
body.home nav  sl-bar {
	padding-right: var(--sl-spacing-x-large);
	font-size: var(--sl-font-size-large);
	color: var(--color-navbar);
	margin-left: 0;
	& div.logo {
		display: flex;
		align-items: center;
	}
 & a { }

	@media( --sl-breakpoint-xs ) {
		& .canvas { display: none  }
		& h1 { margin: auto }
		& sl-avatar{ display: none }
		} 
	}

/*  Spezifische Formatierung der Navigation für die Homepage */
body.page nav  sl-bar {
	padding-right: var(--sl-spacing-3x-small);
	font-size: var(--sl-font-size-large);
	color: var(--color-navbar);
 & a { }
}

body nav sl-bar logo, h3 logo  { 
	font-size: var(--fluid-font-size-h3);
	font-weight: 880;
	color: var(--color-hieronymus-base);
	font-family: var(--display-serif);
	text-shadow: 3px 3px 3px var(--sl-color-gray-700);
	letter-spacing: var(--sl-spacing-2x-small);
	font-weight: var(--sl-font-weight-bold);  

	& letter {
		color: var(--color-hieronymus);
		text-shadow: 1px 1px 1px var(--color-light-gray); 
		vertical-align: -0.4467rem;

	}

}

